<template>
  <div class="main-search-page">
    <div class="title">
      <div class="container box-search" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
        <img src="@/assets/icons/back.svg" v-if="localDisplayLang === 'eng'" alt @click="initialRoute" />
        <img src="@/assets/icons/back.svg" class="rotate-img" v-else alt @click="initialRoute" />
        &nbsp;&nbsp;&nbsp;
        <p>
          {{ $t("Search results for") }}
          <span>"{{ routeSearchQuery }}"</span>
        </p>
      </div>
    </div>
    <div class="container searched-contents-container">
      <div v-if="!categorizedContents">
        <div v-for="(item, index) in 3" :key="index">
          <categoriesPlaceholder></categoriesPlaceholder>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, index) in categorizedContents" :key="index">
          <SearchSlider :types="item" :localLang="localDisplayLang"></SearchSlider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
export default {
  data() {
    return {
      routeSearchQuery: null,
      movieObj: null,
      searchedData: null,
      searchedSeriesData: null,
      searchedMoviesData: null,
      categorizedSeries: null,
      categorizedMovies: null,
      categorizedContents: null,
    };
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
      this.categorizedContents = null;
      this.fetchSearchedQueryData();
    });

    this.routeSearchQuery = this.$route.params.query;

    this.fetchSearchedQueryData();
  },
  mounted() {
    //event for fetched Searched Data
    eventBus.$on("render-search-to-dom", this.showSearchedData);
    eventBus.$on("search-query-string", this.getSearchedData);
    eventBus.$on("show-search-in-dom", this.showSearchedData);
  },
  methods: {
    initialRoute() {
      this.$router.push({ name: "Home" });
    },

    //search based on keyword
    fetchSearchedQueryData() {
      let payload = {
        query: this.routeSearchQuery,
        displaylanguage: this.localDisplayLang,
        pagesize: 100,
      };

      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        payload.genre = "Kids";
      }

      eventBus.$emit("search-api-call", payload);
    },

    //display Searched Data
    showSearchedData(payload) {
      this.searchedData = [...payload.data];
      this.searchedSeriesData = this.searchedData.filter((element) => {
        return element.objecttype === "SERIES";
      });
      this.categorizedSeries = [{ title: "SERIES", objects: this.searchedSeriesData }];
      //   console.log("categorizedSeries------->", this.categorizedSeries);

      this.searchedMoviesData = this.searchedData.filter((element) => {
        return element.objecttype === "CONTENT";
      });
      this.categorizedMovies = [{ title: "MOVIES", objects: this.searchedMoviesData }];
      //   console.log("categorizedMovies------->", this.categorizedMovies);

      this.categorizedContents = [...this.categorizedSeries, ...this.categorizedMovies];
      console.log("categorizedContents---->", this.categorizedContents);
    },

    //if already present in search page
    getSearchedData(data) {
      this.categorizedContents = null;
      let payload = {
        query: data,
        displaylanguage: this.localDisplayLang,
        pagesize: 100,
      };

      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        payload.genre = "Kids";
      }

      // console.log("already searched data---------->", payload);
      eventBus.$emit("search-page-call", payload);
    },
  },
  beforeDestroy() {
    eventBus.$off("render-search-to-dom");
    eventBus.$off("search-query-string");
    eventBus.$off("show-search-in-dom");
  },
  components: {
    categoriesPlaceholder: () =>
      import(/* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"),
    SearchSlider: () => import(/* webpackChunkName: "SearchSlider" */ "./SearchSlider.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.main-search-page {
  height: 100vh;
  .title {
    background: linear-gradient(90deg, #1d1d1d 0%, #1a1a1a 100%);
    .box-search {
      display: flex;
      img {
        cursor: pointer;
        width: 1rem;
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
      .rotate-img {
        transform: rotate(180deg);
      }
      p {
        opacity: 1;
        color: $clr-popup-para;
        font-family: $font-regular;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.6rem;
        span {
          color: $clr-light-gd3;
        }
      }
    }
  }
}
</style>
